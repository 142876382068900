<template>
    <div>
        <div
            v-if="loadingStatus"
            class="my-5">
            <loader />
        </div>
        <div v-else>
            <styled-callout
                v-if="dealerInventoryType == 'feed_inventory' && !feedRdiConfigured"
                class="layout align-center mb-4">
                <div class="flex shrink mr-3">
                    <status-icon
                        value="warning"
                        size="25" />
                </div>
                <div class="flex">
                    <p>
                        <strong>
                            You still need to work with {{ dealerFeedRdi.vehicle_data_source.name }}
                            to obtain an ID for your feed before we can process inventory
                        </strong>
                        <br>
                        <a
                            href="#"
                            @click.prevent="$store.dispatch('goToStepByFeature', 'feed_inventory')">
                            <span>
                                Click here to return to the feed inventory setup
                                step and activate your feed
                            </span>
                        </a>
                    </p>
                </div>
            </styled-callout>
            <v-alert
                type="error"
                class="mb-4"
                :value="error">
                {{ error }}
            </v-alert>
            <h2>Inventory Status</h2>
            <p class="mb-5">
                Congratulations!  You now have successfully setup inventory!
                It can take some time for us to process everything so it's usable
                for advertising so feel free to continue with the process but just
                keep in mind that processing must be completed to finish onboarding.
            </p>
            <div class="layout wrap text-xs-center mx-4">
                <div class="flex xs12 md4 mb-5">
                    <div class="grey--text mb-3">
                        STATUS
                    </div>
                    <div class="status-text mb-4">
                        <status-icon
                            v-if="processStatusData.icon"
                            :value="processStatusData.icon"
                            :color="processStatusData.color"
                            size="20"
                            class="mb-1" />
                        <styled-loader
                            v-if="processStatusData.loader"
                            :size="20" />
                        <span
                            :style="{
                                color: processStatusData.color
                            }">
                            {{ processStatusData.label }}
                        </span>
                    </div>
                    <div class="grey--text mb-5">
                        <em>{{ lastProcessedLabel }}</em>
                    </div>
                    <table>
                        <tr>
                            <td class="small-label">
                                Added
                            </td>
                            <td class="small-stat">
                                {{ stats.processCreated }}
                            </td>
                        </tr>
                        <tr>
                            <td class="small-label">
                                Updated
                            </td>
                            <td class="small-stat">
                                {{ stats.processUpdated }}
                            </td>
                        </tr>
                        <tr>
                            <td class="small-label">
                                Removed
                            </td>
                            <td class="small-stat">
                                {{ stats.processDeleted }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="flex xs12 offset-md1 md7 mb-5">
                    <div class="grey--text mb-3">
                        VEHICLE STATS
                    </div>
                    <div class="layout mb-4">
                        <div class="flex xs4">
                            <div>IN FEED</div>
                            <div class="large-stat mb-3">
                                {{ stats.vehiclesInFeedTotal }}
                            </div>
                            <div class="layout">
                                <div class="flex">
                                    <div class="small-label">
                                        NEW
                                    </div>
                                    <div class="small-stat">
                                        {{ stats.vehiclesInFeedNew }}
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="small-label">
                                        USED
                                    </div>
                                    <div class="small-stat">
                                        {{ stats.vehiclesInFeedUsed }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex xs4">
                            <div>REJECTED</div>
                            <div
                                class="large-stat mb-3"
                                :style="{
                                    color: colors.red
                                }">
                                {{ stats.vehiclesRejectedTotal }}
                            </div>
                        </div>
                        <div class="flex xs4">
                            <div>PROCESSED</div>
                            <div
                                class="large-stat mb-3"
                                :style="{
                                    color: colors.green
                                }">
                                {{ stats.vehiclesProcessedTotal }}
                            </div>
                            <div class="layout">
                                <div class="flex">
                                    <div class="small-label">
                                        NEW
                                    </div>
                                    <div
                                        class="small-stat"
                                        :style="{
                                            color: colors.green
                                        }">
                                        {{ stats.vehiclesProcessedNew }}
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="small-label">
                                        USED
                                    </div>
                                    <div
                                        class="small-stat"
                                        :style="{
                                            color: colors.green
                                        }">
                                        {{ stats.vehiclesProcessedUsed }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="vehicle-stat-table">
                        <tr>
                            <td />
                            <td class="small-label">
                                IMAGES
                            </td>
                            <td class="small-label">
                                VDP URLS
                            </td>
                        </tr><tr>
                            <td class="small-label">
                                From Feed
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesImagesFeed }}
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesUrlsFeed }}
                            </td>
                        </tr>
                        <tr>
                            <td class="small-label">
                                Hydrated
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesImagesHydrated }}
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesUrlsHydrated }}
                            </td>
                        </tr>
                        <tr>
                            <td class="small-label">
                                Total
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesImagesTotal }}
                            </td>
                            <td class="small-stat">
                                {{ stats.vehiclesUrlsTotal }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="layout align-center">
                <back-button
                    @click.prevent="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    @click="submit">
                    CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import Loader from '@/components/globals/Loader';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledLoader from '@/components/globals/StyledLoader';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledButton from '@/components/globals/StyledButton';
import colors from '@/helpers/colors';
import { mapState, mapGetters } from 'vuex';
import numeral from 'numeral';
import { get } from 'lodash';
import getNumberFormats from '@/helpers/numberFormats';
import { getCurrentProcessInfo } from '@/helpers/inventory-dashboard/getCurrentProcessInfo';

export default {
    name: 'OnboardingInventoryStatus',
    components: {
        BackButton,
        StyledCallout,
        StatusIcon,
        Loader,
        StyledLoader,
        StyledButton
    },
    props: {
        keepAlive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            colors,
            loadingStatus: false,
            error: null,
            processes: [],
            uploadProcess: null,
            urlProcess: null,
            feedProcess: null,
            runningProcess: null,
            interval: null
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerInventoryConfigured',
            'dealerInventoryType',
            'dealerFeedRdi'
        ]),
        rdis() {
            return this.currentDealer.remote_dealer_identifiers.data.map(rdi => rdi.id);
        },
        feedRdiConfigured() {
            if (!this.dealerFeedRdi) {
                return false;
            }

            return (this.dealerFeedRdi.id_string.length && this.dealerFeedRdi.id_string !== '-- temporary --');
        },
        processStatus() {
            if (this.dealerInventoryType === 'feed_inventory' && !this.feedRdiConfigured) {
                return 'unconfigured';
            }

            // If there's no identified feed process and running process in the list we should
            // default to queued since it means it hasn't processed yet
            if (!this.runningProcess && !this.feedProcess) {
                return 'queued';
            }

            for (let i = 0; i < this.processes.length; i++) {
                let process = this.processes[i];

                // Ensure the process is supported
                if (!['feed-processor', 'vdp-url-processor', 'upload-exports'].includes(process.process_name)) {
                    continue;
                }

                if (process.status === 'processing') {
                    return 'processing';
                }
                if (process.status === 'failed') {
                    return 'failed';
                }
                if (process.status === 'finished') {
                    return 'finished';
                }
            }

            return 'queued';
        },
        runningProcessLabel() {
            if (!this.runningProcess) {
                return null;
            }

            const runningProcessLabels = {
                'feed-processor': 'Processing feed',
                'vdp-url-processor': 'Processing URLs',
                'upload-exports': 'Uploading data'
            };
            return runningProcessLabels[this.runningProcess.process_name];
        },
        processStatusData() {
            const map = {
                processing: {
                    label: this.runningProcessLabel,
                    color: colors.blue,
                    icon: null,
                    loader: true
                },
                failed: {
                    label: 'Failed',
                    color: colors.red,
                    icon: 'error',
                    loader: false
                },
                finished: {
                    label: 'Processed',
                    color: colors.green,
                    icon: 'success',
                    loader: false
                },
                queued: {
                    label: 'Queued',
                    color: colors.orange,
                    icon: 'warning',
                    loader: false
                },
                unconfigured: {
                    label: 'Not configured',
                    color: colors.orange,
                    icon: 'warning',
                    loader: false
                }
            };

            return map[this.processStatus];
        },
        lastProcessed() {
            if (this.dealerInventoryType === 'feed_inventory' && !this.feedRdiConfigured) {
                return null;
            }


            const recentProcess = this.processes.find(process => {
                return ['feed-processor', 'vdp-url-processor', 'upload-exports'].includes(process.process_name);
            });

            if (recentProcess) {
                return recentProcess.finished_at;
            }

            return null;
        },
        lastProcessedLabel() {
            if (this.dealerInventoryType === 'feed_inventory' && !this.feedRdiConfigured) {
                return 'Waiting on configuration';
            }

            if (this.lastProcessed) {
                const timeAgo = this.$moment.utc(this.lastProcessed.date).local().fromNow();
                return `Last processed ${timeAgo}`;
            }

            if (this.processStatus === 'processing') {
                return 'Currently processing';
            }

            return 'Waiting to process';
        },
        stats() {
            const statsMap = {
                processCreated: 'feedProcess.created',
                processUpdated: 'feedProcess.updated',
                processDeleted: 'feedProcess.deleted',
                vehiclesInFeedTotal: 'feedProcess.details.rdi_vehicles',
                vehiclesInFeedNew: 'feedProcess.details.rdi_vehicles_new',
                vehiclesInFeedUsed: 'feedProcess.details.rdi_vehicles_used',
                vehiclesRejectedTotal: 'feedProcess.details.rejected',
                vehiclesProcessedTotal: 'feedProcess.buyerbridge',
                vehiclesProcessedNew: 'feedProcess.buyerbridge_new',
                vehiclesProcessedUsed: 'feedProcess.buyerbridge_used',
                vehiclesImagesTotal: 'feedProcess.details.rdi_vehicles_with_images',
                vehiclesImagesFeed: 'feedProcess.details.rdi_vehicles_with_images_from_feed',
                vehiclesImagesHydrated: 'feedProcess.details.rdi_vehicles_with_images_from_marketcheck_hydration',
                vehiclesUrlsTotal: 'urlProcess.details.rdi_vehicles_with_vdp_url',
                vehiclesUrlsFeed: 'urlProcess.details.rdi_vehicles_with_vdp_url_from_feed',
                vehiclesUrlsHydrated: 'urlProcess.details.rdi_vehicles_with_vdp_url_from_marketcheck_hydration',
            };

            const formattedStats = {};

            for ( let stat in statsMap) {
                let currentStat = get(this, statsMap[stat], null);
                formattedStats[stat] = (currentStat !== null) ? numeral(currentStat).format(this.numberFormats.number) : '-';
            }

            return formattedStats;
        }
    },
    activated() {
        this.init();
    },
    deactivated() {
        clearInterval(this.interval);
    },
    methods: {
        async submit() {
            this.$store.dispatch('goToNextStep');
        },
        async init() {
            this.loadingStatus = true;
            await this.getInventoryProcessingStatus();
            this.loadingStatus = false;

            // Now start checking the status on an interval
            this.interval = setInterval(() => {
                this.getInventoryProcessingStatus();
            }, 2000);
        },
        async getInventoryProcessingStatus() {
            try {
                const response = await this.$apiRepository.getInventoryProcesses(this.currentDealer.id);
                this.processes = response.data;
                const processingInfo = getCurrentProcessInfo(this.processes);
                this.runningProcess = processingInfo.running_process;
                this.uploadProcess = processingInfo.upload_process;
                this.urlProcess = processingInfo.url_process;
                this.feedProcess = processingInfo.feed_process;
            } catch (error) {
                console.error('Error retrieving inventory processes', error);
                this.error = 'Error retrieving inventory processes';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.status-icon {
    display: inline-flex;
}

.status-text {
    color: $blue-primary;
    font-size: 2rem;
    line-height: 2rem;
}

.small-label {
    font-size: 0.8rem;
}

.small-stat {
    color: $blue-primary;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.large-stat {
    color: $blue-primary;
    font-size: 2.4rem;
    line-height: 2.2rem;
    font-weight: 600;
}

table {
    width: 80%;
    margin: 0 auto;
    td {
        padding: 8px 5px;
    }
    td:first-child {
        text-align: right;
        width: 85px;
        color: $gray;
    }
}
</style>
