var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingStatus)?_c('div',{staticClass:"my-5"},[_c('loader')],1):_c('div',[(_vm.dealerInventoryType == 'feed_inventory' && !_vm.feedRdiConfigured)?_c('styled-callout',{staticClass:"layout align-center mb-4"},[_c('div',{staticClass:"flex shrink mr-3"},[_c('status-icon',{attrs:{"value":"warning","size":"25"}})],1),_c('div',{staticClass:"flex"},[_c('p',[_c('strong',[_vm._v(" You still need to work with "+_vm._s(_vm.dealerFeedRdi.vehicle_data_source.name)+" to obtain an ID for your feed before we can process inventory ")]),_c('br'),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('goToStepByFeature', 'feed_inventory')}}},[_c('span',[_vm._v(" Click here to return to the feed inventory setup step and activate your feed ")])])])])]):_vm._e(),_c('v-alert',{staticClass:"mb-4",attrs:{"type":"error","value":_vm.error}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('h2',[_vm._v("Inventory Status")]),_c('p',{staticClass:"mb-5"},[_vm._v(" Congratulations! You now have successfully setup inventory! It can take some time for us to process everything so it's usable for advertising so feel free to continue with the process but just keep in mind that processing must be completed to finish onboarding. ")]),_c('div',{staticClass:"layout wrap text-xs-center mx-4"},[_c('div',{staticClass:"flex xs12 md4 mb-5"},[_c('div',{staticClass:"grey--text mb-3"},[_vm._v(" STATUS ")]),_c('div',{staticClass:"status-text mb-4"},[(_vm.processStatusData.icon)?_c('status-icon',{staticClass:"mb-1",attrs:{"value":_vm.processStatusData.icon,"color":_vm.processStatusData.color,"size":"20"}}):_vm._e(),(_vm.processStatusData.loader)?_c('styled-loader',{attrs:{"size":20}}):_vm._e(),_c('span',{style:({
                            color: _vm.processStatusData.color
                        })},[_vm._v(" "+_vm._s(_vm.processStatusData.label)+" ")])],1),_c('div',{staticClass:"grey--text mb-5"},[_c('em',[_vm._v(_vm._s(_vm.lastProcessedLabel))])]),_c('table',[_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" Added ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.processCreated)+" ")])]),_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" Updated ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.processUpdated)+" ")])]),_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" Removed ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.processDeleted)+" ")])])])]),_c('div',{staticClass:"flex xs12 offset-md1 md7 mb-5"},[_c('div',{staticClass:"grey--text mb-3"},[_vm._v(" VEHICLE STATS ")]),_c('div',{staticClass:"layout mb-4"},[_c('div',{staticClass:"flex xs4"},[_c('div',[_vm._v("IN FEED")]),_c('div',{staticClass:"large-stat mb-3"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesInFeedTotal)+" ")]),_c('div',{staticClass:"layout"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"small-label"},[_vm._v(" NEW ")]),_c('div',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesInFeedNew)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"small-label"},[_vm._v(" USED ")]),_c('div',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesInFeedUsed)+" ")])])])]),_c('div',{staticClass:"flex xs4"},[_c('div',[_vm._v("REJECTED")]),_c('div',{staticClass:"large-stat mb-3",style:({
                                color: _vm.colors.red
                            })},[_vm._v(" "+_vm._s(_vm.stats.vehiclesRejectedTotal)+" ")])]),_c('div',{staticClass:"flex xs4"},[_c('div',[_vm._v("PROCESSED")]),_c('div',{staticClass:"large-stat mb-3",style:({
                                color: _vm.colors.green
                            })},[_vm._v(" "+_vm._s(_vm.stats.vehiclesProcessedTotal)+" ")]),_c('div',{staticClass:"layout"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"small-label"},[_vm._v(" NEW ")]),_c('div',{staticClass:"small-stat",style:({
                                        color: _vm.colors.green
                                    })},[_vm._v(" "+_vm._s(_vm.stats.vehiclesProcessedNew)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"small-label"},[_vm._v(" USED ")]),_c('div',{staticClass:"small-stat",style:({
                                        color: _vm.colors.green
                                    })},[_vm._v(" "+_vm._s(_vm.stats.vehiclesProcessedUsed)+" ")])])])])]),_c('table',{staticClass:"vehicle-stat-table"},[_vm._m(0),_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" From Feed ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesImagesFeed)+" ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesUrlsFeed)+" ")])]),_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" Hydrated ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesImagesHydrated)+" ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesUrlsHydrated)+" ")])]),_c('tr',[_c('td',{staticClass:"small-label"},[_vm._v(" Total ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesImagesTotal)+" ")]),_c('td',{staticClass:"small-stat"},[_vm._v(" "+_vm._s(_vm.stats.vehiclesUrlsTotal)+" ")])])])])]),_c('div',{staticClass:"layout align-center"},[_c('back-button',{on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('goToPreviousStep')}}}),_c('styled-button',{staticClass:"ml-auto",on:{"click":_vm.submit}},[_vm._v(" CONTINUE ")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"small-label"},[_vm._v(" IMAGES ")]),_c('td',{staticClass:"small-label"},[_vm._v(" VDP URLS ")])])
}]

export { render, staticRenderFns }